import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import colorVariables from '@densityco/ui/variables/colors.json';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    color: colorVariables.white,
    backgroundColor: colorVariables.midnight,
    padding: '8px 12px',
    fontSize: 12,
    fontWeight: 600,
  },
  tooltipPlacementBottom: {
    marginTop: 8,
  },
}));

// Temporary component to make new info popup styles
// TODO: Backport this into density ui
export default function InfoPopupNew({
  target,
  contents,
  placement,
  enterDelay,
  wrap,
  width,
}: {
  target: React.ReactNode;
  contents: React.ReactNode;
  placement?: TooltipProps['placement'];
  enterDelay?: TooltipProps['enterDelay'];
  wrap?: boolean;
  width?: React.ReactText;
}) {
  const classes = useStyles();

  return (
    <Tooltip
      classes={classes}
      title={contents || ''}
      placement={placement || 'bottom-start'}
      enterDelay={typeof enterDelay !== 'undefined' ? enterDelay : 700}
    >
      <div
        style={{
          width: width || 'fit-content',
          whiteSpace: wrap ? undefined : 'nowrap',
        }}
      >
        {target}
      </div>
    </Tooltip>
  );
}
