import { ToastContainer as ReactToastContainer, Slide } from 'react-toastify';

const ToastContainer: React.FC = () => {
  return (
    <ReactToastContainer
      theme="dark"
      position="bottom-center"
      transition={Slide}
      className="toastContainer"
      toastClassName="toast"
      autoClose={3000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      draggable={false}
      icon={false}
      pauseOnFocusLoss
      pauseOnHover
    />
  );
};

export default ToastContainer;
